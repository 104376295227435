<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab> Parcel Return </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container>
        <colissimo />
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import colissimo from '../components/tools/colissimo.vue';

export default {
  components: { colissimo },
  data() {
    return {
      tab: null,
    };
  },
};
</script>
