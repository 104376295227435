<template>
  <v-card class="pa-2" >
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            label="Company Name or Firstname Lastname"
            dense
            v-model="sender.companyName"
            autocomplete="client_companyName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Address"
            dense
            v-model="sender.address"
            autocomplete="client_address"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Address 2"
            dense
            v-model="sender.address2"
            autocomplete="client_address2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Post Code"
            dense
            v-model="sender.zipCode"
            autocomplete="client_zipCode"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            autocomplete="client_city"
            label="City"
            dense
            v-model="sender.city"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            label="Weight (kg)"
            dense
            type="number"
            v-model.number="weight"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8">
          <counters-selector-autocomplete v-model="selectedCounters" />
          <p v-if="selectedCounters.length" class="caption">
            Est.: {{ estimatedWeight }}kg ~
            {{ estimatedWeight + estimatedWeightAccessories }}kg (with
            accessories)
          </p>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" sm="6">
          <v-radio-group dense v-model="reason">
            <template v-slot:label>
              <div><strong>Reasons</strong> of return</div>
            </template>
            <v-radio
              label="After-sales service"
              value="after-sales service"
            ></v-radio>
            <v-radio label="End Of test" value="End of test"></v-radio>
            <v-radio label="Other" value="other"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea label="Comments" v-model="comments"></v-textarea>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Email (Send label automatically to the customer)"
            dense
            type="email"
            v-model="email"
            autocomplete="client_email"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-btn small color="error" @click="clearForm">clear</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        small
        color="success"
        :disabled="validForm"
        :loading="loading"
        @click="askLabel"
        >send</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import countersSelectorAutocomplete from '../selectors/countersSelectorAutocomplete.vue';

export default {
  components: { countersSelectorAutocomplete },
  data() {
    return {
      loading: false,
      selectedCounters: [],
      reason: '',
      comments: '',
      weight: '',
      sender: {
        companyName: '',
        address: '',
        address2: '',
        zipCode: '',
        city: '',
      },
      email: '',
    };
  },
  computed: {
    estimatedWeight() {
      return this.selectedCounters.length * 0.75;
    },
    estimatedWeightAccessories() {
      return this.selectedCounters.length * 0.15;
    },
    validForm() {
      return !(
        this.sender.companyName
        && this.sender.address
        && this.sender.city
        && this.sender.zipCode
        && this.weight
      );
    },
  },
  methods: {
    ...mapActions(['requestParcelReturnLabel']),
    clearForm() {
      this.sender = {
        companyName: '',
        address: '',
        address2: '',
        zipCode: '',
        city: '',
      };
      this.weight = '';
      this.selectedCounters = [];
      this.reason = '';
      this.comments = '';
      this.email = '';
    },
    async askLabel() {
      this.loading = true;
      const payload = {
        sender: { ...this.sender },
        weight: this.weight,
        comments: this.comments,
        reason: this.reason,
        counters: this.selectedCounters,
      };
      if (this.email) {
        payload.sender.mail = this.email;
      }
      try {
        const tps = await this.requestParcelReturnLabel(payload);
        window.open(tps.label);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
  },
};
</script>
